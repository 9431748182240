require('../scss/app.scss');
const $ = require('jquery');
import 'bootstrap';

$(document).ready(function(){
    $('.loader').fadeOut('slow');



    $(".scroll-down a").click(function() {
        $('html, body').animate({
            scrollTop:$('.scroll-down').offset().top
	}, 'slow');
        return false;
    });
    $(".go-top a").click(function() {
        $('html, body').animate({
            scrollTop:$('body').offset().top
        }, 'slow');
        return false;
    });

    $('.carousel').carousel();

    $('.box-story .story-img').mouseover(function(){
        $('.box-story .story-img .over-infos').hide();
        $(this).find('.over-infos').show();
    });
    $('.box-story .story-img .over-infos').mouseout(function(){
        $(this).hide();
    })

    $('.authors-list .authors-list-img').mouseover(function(){
        $('.authors-list .authors-list-img .over-author').hide();
        $(this).find('.over-author').show();
    });
    $('.authors-list .authors-list-img .over-author').mouseout(function(){
        $(this).hide();
    });

    $('.contact-author').click(function(){
        $('#modal-contact').modal();
        return false;
    });
    $('.report').click(function(){
        $('#modal-report').modal();
        return false;
    });
    $('.lost-password a').click(function(){
        $('#modal-password').modal();
        return false;
    });
    $('.search').click(function(){
        $('#modal-search').modal();
        return false;
    });
    $('.account-pal').click(function() {
        $('#modal-pal-'+$(this).attr('data-id')).modal();
        return false;
    })
    $('.custom-button-ho a').click(function(){
        $('#modal-ho').modal();
        return false;
    });
    $('.modal-erreur').modal();

    $('.ho-participation').click(function() {
        $('#modal-ho-participation-'+$(this).attr('data-id')).modal();
        return false;
    })
    $('.ho-vote').click(function() {
        $('#modal-ho-vote-'+$(this).attr('data-id')).modal();
        return false;
    });
    $('.delete-account').click(function() {
        $('#modal-delete-account').modal();
        return false;
    })
    $('.admin-moderation-rapide').click(function() {
        var id = $(this).prop('id');
        $('.modal-moderation-rapide-' + id).modal();

        $('#modal-moderation-rapide select').html('');
        $('#modal-moderation-rapide .hidden-texts').html('');
        $.post('/avertissement/'+id)
            .done(function(data) {
                var data = window.JSON.parse(data);

                $('#modal-moderation-rapide select').append('<option>--- Sélectionnez un motif</option>');
                for (var i=0; i < data.length; i++) {
                    $('#modal-moderation-rapide select').append('<option value="'+data[i]['key']+'">'+data[i]['title']+'</option>');
                    $('#modal-moderation-rapide .hidden-texts').append('<input type="hidden" class="hidden-'+data[i]['key']+'" value="'+data[i]['text']+'" />')
                }
            });

        return false;
    })
    $('#modal-moderation-rapide select').change(function() {
        $('#modal-moderation-rapide .message').html($('#modal-moderation-rapide .hidden-texts .hidden-'+$(this).val()).val());
    });

    $('.form-connect').submit(function( event ) {
        var valid = true;
        if($('.form-connect .username').val() == '') {
            valid = false;
            $('.form-connect .connect-username').removeClass('hidden');
        }
        else {
            valid = true;
            $('.form-connect .connect-username').addClass('hidden');
        }
        if($('.form-connect .password').val() == '') {
            valid = false;
            $('.form-connect .connect-password').removeClass('hidden');
        }
        else {
            valid = true;
            $('.form-connect .connect-password').addClass('hidden');
        }

        if(valid == false) return false;
    });

    $('.form-report').submit(function( event ) {
        var validObject = true;
        var validContent = true;
        if($('.form-report .object').val() == '') {
            validObject = false;
            $('.form-report .object-error').show();
        }
        else {
            validObject = true;
            $('.form-report .object-error').hide();
        }
        if($('.form-report .content').val() == '') {
            validContent = false;
            $('.form-report .content-error').show();
        }
        else {
            validContent = true;
            $('.form-report .content-error').hide();
        }

        if(validObject == false || validContent == false) return false;
    });
    $('.form-contact').submit(function( event ) {
        var validObject = true;
        var validContent = true;
        if($('.form-contact .object').val() == '') {
            validObject = false;
            $('.form-contact .object-error').show();
        }
        else {
            validObject = true;
            $('.form-contact .object-error').hide();
        }
        if($('.form-contact .content').val() == '') {
            validContent = false;
            $('.form-contact .content-error').show();
        }
        else {
            validContent = true;
            $('.form-contact .content-error').hide();
        }

        if(validObject == false || validContent == false) return false;
    });
    $('.review-answer').submit(function() {
        if($(this).find('textarea').val() == '') {
            $(this).find('ul').show();
            return false;
        }
    })
    $('.form-comment').submit(function() {
        if($('.form-comment textarea').val().length > 25) {
            $('.form-comment').hide();
            $('.single-item .loader').show();
        }
    })

    var last_valid_selection = null;

    $('#account_story_genre').change(function(event) {

        if ($(this).val().length > 3) {

            $(this).val(last_valid_selection);
        } else {
            last_valid_selection = $(this).val();
        }
    });

    $('.chapter-to-go').change(function(){
        $(location).attr('href', $('.chapter-to-go').val());
    })

    $('.account-warning .plus').click(function() {
        if ($(this).parent().find('.plus-texte').css('display') == 'none') {
            $(this).parent().find('.fas').removeClass('fa-angle-down');
            $(this).parent().find('.fas').addClass('fa-angle-up');
            $(this).parent().find('.plus-texte').slideDown();
        }
        else {
            $(this).parent().find('.fas').addClass('fa-angle-down');
            $(this).parent().find('.fas').removeClass('fa-angle-up');
            $(this).parent().find('.plus-texte').slideUp();
        }
    })

    window.changeNotif = function(name, type){
        if(type == 'MAIL') {
           if($('#notif-'+name+'-'+type).prop('checked') == true) {
                $('#notif-'+name+'-NONE').prop('checked', false);
                $('#notif-'+name+'-'+type).parent().parent().parent().addClass('active');
                $('#notif-'+name+'-NONE').parent().parent().parent().removeClass('active');
            }
            else {
                if($('#notif-'+name+'-SITE').prop('checked') == true) {
                    $('#notif-'+name+'-NONE').prop('checked', false);
                    $('#notif-'+name+'-NONE').parent().parent().parent().removeClass('active');
                }
                else {
                    $('#notif-'+name+'-NONE').prop('checked', true);
                    $('#notif-'+name+'-NONE').parent().parent().parent().addClass('active');
                }
                $('#notif-'+name+'-'+type).parent().parent().parent().removeClass('active');
            }
        }
        if(type == 'SITE') {
           if($('#notif-'+name+'-'+type).prop('checked') == true) {
                $('#notif-'+name+'-NONE').prop('checked', false);
                $('#notif-'+name+'-'+type).parent().parent().parent().addClass('active');
                $('#notif-'+name+'-NONE').parent().parent().parent().removeClass('active');
            }
            else {
                if($('#notif-'+name+'-MAIL').prop('checked') == true) {
                    $('#notif-'+name+'-NONE').prop('checked', false);
                    $('#notif-'+name+'-NONE').parent().parent().parent().removeClass('active');
                }
                else {
                    $('#notif-'+name+'-NONE').prop('checked', true);
                    $('#notif-'+name+'-NONE').parent().parent().parent().addClass('active');
                }
                $('#notif-'+name+'-'+type).parent().parent().parent().removeClass('active');
            }
        }
        if(type == 'ALL') {
            if($('#notif-'+name+'-'+type).prop('checked') == true) {
                $('#notif-'+name+'-NONE').prop('checked', false);
                $('#notif-'+name+'-NONE').parent().parent().parent().removeClass('active');

                $('#notif-'+name+'-NOTCHAPTER').prop('checked', false);
                $('#notif-'+name+'-NOTCHAPTER').parent().parent().parent().removeClass('active');

                $('#notif-'+name+'-'+type).parent().parent().parent().addClass('active');
            }
            else {
                $('#notif-'+name+'-NONE').prop('checked', true);
                $('#notif-'+name+'-NONE').parent().parent().parent().addClass('active');

                $('#notif-'+name+'-NOTCHAPTER').prop('checked', true);
                $('#notif-'+name+'-NOTCHAPTER').parent().parent().parent().addClass('active');

                $('#notif-'+name+'-'+type).parent().parent().parent().removeClass('active');
            }
        }
        if(type == 'NOTCHAPTER') {
            if($('#notif-'+name+'-'+type).prop('checked') == true) {
                $('#notif-'+name+'-NONE').prop('checked', false);
                $('#notif-'+name+'-NONE').parent().parent().parent().removeClass('active');

                $('#notif-'+name+'-ALL').prop('checked', false);
                $('#notif-'+name+'-ALL').parent().parent().parent().removeClass('active');

                $('#notif-'+name+'-'+type).parent().parent().parent().addClass('active');
            }
            else {
                $('#notif-'+name+'-ALL').prop('checked', true);
                $('#notif-'+name+'-ALL').parent().parent().parent().addClass('active');

                $('#notif-'+name+'-NONE').prop('checked', true);
                $('#notif-'+name+'-NONE').parent().parent().parent().addClass('active');

                $('#notif-'+name+'-'+type).parent().parent().parent().removeClass('active');
            }
        }
        if(type == 'NONE') {
            if($('#notif-'+name+'-'+type).prop('checked') == true) {
                $('#notif-'+name+'-MAIL').prop('checked', false);
                $('#notif-'+name+'-MAIL').parent().parent().parent().removeClass('active');
                $('#notif-'+name+'-SITE').prop('checked', false);
                $('#notif-'+name+'-SITE').parent().parent().parent().removeClass('active');
                $('#notif-'+name+'-ALL').prop('checked', false);
                $('#notif-'+name+'-ALL').parent().parent().parent().removeClass('active');
                $('#notif-'+name+'-NOTCHAPTER').prop('checked', false);
                $('#notif-'+name+'-NOTCHAPTER').parent().parent().parent().removeClass('active');
                $('#notif-'+name+'-'+type).parent().parent().parent().addClass('active');
            }
            else {
                $('#notif-'+name+'-MAIL').prop('checked', true);
                $('#notif-'+name+'-MAIL').parent().parent().parent().addClass('active');
                $('#notif-'+name+'-SITE').prop('checked', true);
                $('#notif-'+name+'-SITE').parent().parent().parent().addClass('active');
                $('#notif-'+name+'-ALL').prop('checked', true);
                $('#notif-'+name+'-ALL').parent().parent().parent().addClass('active');
                $('#notif-'+name+'-NOTCHAPTER').prop('checked', true);
                $('#notif-'+name+'-NOTCHAPTER').parent().parent().parent().addClass('active');
                $('#notif-'+name+'-'+type).parent().parent().parent().removeClass('active');
            }
        }

        var typeValue = $('#notif-'+name+'-'+type).prop('checked');

        $.post('/compte/parametres/notifications/change', {
            name: name,
            type: type,
            typeValue: typeValue
        });
    }
    var letter = 'NaN';
    var offset = 'NaN';
    var limit = 'NaN';

    window.chargeMembers = function(lett, off, lim){
        if(letter == 'NaN') letter = lett;
        if(offset == 'NaN') offset = off;
        if(limit == 'NaN') limit = lim;

        $.post('/membres-charge-plus', {
            letter: letter,
            offset: offset
        })
        .done(function(data) {
            offset = offset + limit;
            $('.authors-list .row').append(data);
            if(data == '') {
                $('.load-more').hide()
            }
        });
    }

    window.overAuthorList = function(id) {
        $('.authors-list .authors-list-img .over-author').hide();
        $('.over-author-'+id).show();
    }
    window.outAuthorList = function(id) {
        $('.over-author-'+id).hide();
    }

    window.chargeStories = function(off, lim, genre, type, status, rating, finished){
        if(offset == 'NaN') offset = off;
        if(limit == 'NaN') limit = lim;

        $.post('/histoires-charge-plus', {
            offset: offset,
            genre: genre,
            type: type,
            status: status,
            rating: rating,
            finished: finished
        })
            .done(function(data) {
                offset = offset + limit;
                $('.stories-list .row').append(data);
                if(data == '') {
                    $('.load-more').hide()
                }
            });
    }

    window.overStoryList = function(id) {
        $('.stories-list .stories-list-img .over-story').hide();
        $('.over-story-'+id).show();
    }
    window.outStoryList = function(id) {
        $('.over-story-'+id).hide();
    }

    window.chargeBlogs = function(off, lim){
        if(offset == 'NaN') offset = off;
        if(limit == 'NaN') limit = lim;

        $.post('/actualites-charge-plus', {
            offset: offset
        })
        .done(function(data) {
            offset = offset + limit;
            $('.events-list .row').append(data);
            if(data == '') {
                $('.load-more').hide()
            }
        });
    }

    window.followMember = function(id) {
        $.post('/membre-suivre', {
            id: id
        })
        .done(function(data) {
            if($('.follow').hasClass('unfollow')) {
                // On suit le membre
                $('.follow').removeClass('unfollow');
                $('.follow i').removeClass('fa-eye-slash');
                $('.follow i').addClass('fa-eye');
                $('.follow span').html('Suivre le membre');
            }
            else {
                // On ne le suit pas
                $('.follow').addClass('unfollow');
                $('.follow i').addClass('fa-eye-slash');
                $('.follow i').removeClass('fa-eye');
                $('.follow span').html('Ne pas suivre le membre');
            }
        });
    }

    window.addPal = function(id) {
        $.post('/histoire-pal', {
            id: id
        })
            .done(function(data) {
                if($('.follow').hasClass('unfollow')) {
                    // On suit le membre
                    $('.follow').removeClass('unfollow');
                    $('.follow i').removeClass('far');
                    $('.follow i').addClass('fas');
                    $('.follow span').html('Ajouter à ma pile à lire');
                }
                else {
                    // On ne le suit pas
                    $('.follow').addClass('unfollow');
                    $('.follow i').addClass('far');
                    $('.follow i').removeClass('fas');
                    $('.follow span').html('Retirer de ma pile à lire');
                }
            });
    }

    window.addRead = function(id) {
        $.post('/chapitre-lire', {
            id: id
        })
            .done(function(data) {
                if($('.read').hasClass('active')) {
                    // On suit le membre
                    $('.read').removeClass('active');
                    $('.read i').removeClass('fa-times');
                    $('.read i').addClass('fa-check');
                    $('.read span').html('Marquer comme lu');
                }
                else {
                    // On ne le suit pas
                    $('.read').addClass('active');
                    $('.read i').addClass('fa-times');
                    $('.read i').removeClass('fa-check');
                    $('.read span').html('Marquer comme non lu');
                }
            });
    }

    window.deleteChapter = function(id) {
        $('#modal-deletechapter').modal();
        $('.chapter-to-delete').val(id);
        return false;
    }
    window.deleteChapterConfirm = function() {
        $(location).attr('href', '/compte/chapitre-supprimer?id=' + $('.chapter-to-delete').val());
        return false;
    }

    window.deleteStory = function(id) {
        $('#modal-deletestory').modal();
        $('.story-to-delete').val(id);
        return false;
    }
    window.deleteStoryConfirm = function() {
        $(location).attr('href', '/compte/histoire-supprimer?id=' + $('.story-to-delete').val());
        return false;
    }
    window.checkRead = function(id) {
        $.post('/chapitre-lire', {
            id: id
        })
            .done(function(data) {
                if($('.chapter-list-'+id).hasClass('active')) {
                    $('.chapter-list-'+id).removeClass('active');
                    $('.chapter-list-'+id+' .round i').addClass('fa-times');
                    $('.chapter-list-'+id+' .round i').removeClass('fa-check');
                    $('.chapter-list-'+id+' .state-text').html("Pas<br />lu");
                }
                else {
                    $('.chapter-list-'+id).addClass('active');
                    $('.chapter-list-'+id+' .round i').removeClass('fa-times');
                    $('.chapter-list-'+id+' .round i').addClass('fa-check');
                    $('.chapter-list-'+id+' .state-text').html("Lu");
                }
            });
    }

    wrap('story_contest_storyTemp');

    function wrap(id) {
        $('#'+id+' label').each(function() {
            var $parent = $(this).closest('#story_contest_storyTemp');
            var inputId = $(this).attr('for');
            var $input = $('#' + inputId);
            var $row = new $('<div class="expanded-row"></div>');
            $row.append($(this)).append($input);
            $parent.append($row);
        });
    }
    $('.expanded-row input[type="radio"]').change(function(){
        $('.expanded-row').removeClass('selected');
        $(this).parent().addClass('selected');
    })

    var winHeight = $('.page-chapter .text').height(),
        docHeight = $('.page-chapter .text').height() * 2;
    var max = docHeight - winHeight,
        progressBar = $('progress');

    $('progress').attr('max', max);

    var value = $(window).scrollTop();
    $('progress').attr('value', value);

    $(document).on('scroll', function() {
        value = $(window).scrollTop();
        progressBar.attr('value', value);
    });

    $(document).on('ready', function() {

        /* Set the max scrollable area */
        max = docHeight - winHeight;
        progressBar.attr('max', max);

        $(document).on('scroll', function () {
            value = $(window).scrollTop();
            progressBar.attr('value', value);
        });
    });

    $.post('/reset-notifs');
    checkNotif();

    function checkNotif() {
        var time = 10;

        setTimeout(function(){
            $.post('/verifier-notifs', {route: $('.page-route').val()})
            .done(function(data) {
                data = window.JSON.parse(data);
                var total = data.total;
                if(total == 0) {
                    $('.header-alert-nb').addClass('display-none');
                }
                else {
                    $('.header-alert-nb').removeClass('display-none');
                    $('.header-alert-nb').html(total);
                }

                var nouvelles = data.nouvelles;
                $('.notifs-show').html(nouvelles);
                $('.notifs-show').fadeIn(1000);

                setTimeout(function(){
                    $('.notifs-show').fadeOut(1000);
                }, 10 * 1000);
            });
            checkNotif();
        }, time * 1000);
    }


    /*
    ** HISTOIRES D'OR
     */

    /*
    ** Changement catégorie
     */
    $('#histoire_or_participation_category').change(function() {

        // réinitialiser formulaire au chargement
        $('.histoiresor_participation_form .character').addClass('hide');
        $('#histoire_or_participation_perso').val('');
        $('.histoiresor_participation_form .quote').addClass('hide');
        $('#histoire_or_participation_quote').val('');
        $('.histoiresor_participation_form .explanation').addClass('hide');
        $('#histoire_or_participation_explanation').val('');
        $('.histoiresor_participation_form .all-good').addClass('hide');

        $('.histoiresor_participation_form .working').removeClass('hide');

        var category = $('#histoire_or_participation_category').val();
        var histoire_id = $('.histoire_or_histoire_id').val();
        $.post('/ho-verifier-categ', {
            category: category,
            histoireId: histoire_id
        })
        .done(function(data) {
            var data = window.JSON.parse(data);
            var success = data.success;
            var message = data.message;
            var character = data.character;
            $('.histoire_or_personnage').val(0);

            $('.histoiresor_participation_form .working').addClass('hide');

            if (success) {

                $('.histoire_or_error').addClass('hide');
                // catégorie remontée, on vérifie si c'est une catégorie personnage
                if (character) {
                    // si catégorie personnage, on affiche le champ de saisie de personnage
                    $('.histoiresor_participation_form .character').removeClass('hide');
                    $('.histoire_or_personnage').val(1);
                }
                // on affiche les champs de citation et explication
                $('.histoiresor_participation_form .quote').removeClass('hide');
                $('.histoiresor_participation_form .explanation').removeClass('hide');
                $('.histoiresor_participation_form .all-good').removeClass('hide');

            }
            else {
                // sinon, on affiche le message d'erreur
                $('.histoire_or_error').html(message);
                $('.histoire_or_error').removeClass('hide');
            }
        });
    });

    $('#histoire_or_participation_perso').focusout(function() {
        var category = $('#histoire_or_participation_category').val();
        var histoire_id = $('.histoire_or_histoire_id').val();
        var character = $('#histoire_or_participation_perso').val();

        $.post('/ho-verifier-perso', {
            category: category,
            histoireId: histoire_id,
            character: character
        })
        .done(function(data) {
            var data = window.JSON.parse(data);
            var success = data.success;
            var message = data.message;

            if (success) {
                $('.histoire_or_error').addClass('hide');

                $('.histoiresor_participation_form .quote').removeClass('hide');
                $('.histoiresor_participation_form .explanation').removeClass('hide');
                $('.histoiresor_participation_form .all-good').removeClass('hide');
            }
            else {
                // sinon, on affiche le message d'erreur
                $('.histoire_or_error').html(message);
                $('.histoire_or_error').removeClass('hide');

                $('.histoiresor_participation_form .quote').addClass('hide');
                $('#histoire_or_participation_quote').val('');
                $('.histoiresor_participation_form .explanation').addClass('hide');
                $('#histoire_or_participation_explanation').val('');
                $('.histoiresor_participation_form .all-good').addClass('hide');
            }

        })
    });

    $('.form-ho').submit(function() {

        var category = $('#histoire_or_participation_category').val();
        var histoire_id = $('.histoire_or_histoire_id').val();
        var character = $('#histoire_or_participation_perso').val();
        var is_character = $('.histoire_or_personnage').val();
        var quote = $('#histoire_or_participation_quote').val();
        var explanation = $('#histoire_or_participation_explanation').val();

        var error = false;

        if (category == '') {
            $('.histoiresor_participation_form .category .error').show();
            error = true;
        }
        else {
            $('.histoiresor_participation_form .category .error').hide();
        }

        if (is_character == 1) {
            if (character == '') {
                $('.histoiresor_participation_form .character .error').show();
                error = true;
            }
            else {
                $('.histoiresor_participation_form .character .error').hide();
            }
        }

        if (quote == '') {
            $('.histoiresor_participation_form .quote .error').show();
            error = true;
        }
        else {
            $('.histoiresor_participation_form .quote .error').hide();
        }

        if (explanation == '') {
            $('.histoiresor_participation_form .explanation .error').show();
            error = true;
        }
        else {
            $('.histoiresor_participation_form .explanation .error').hide();
        }

        if (error) return false;

        $('.histoiresor_participation_form .category').addClass('hide');
        $('.histoiresor_participation_form .character').addClass('hide');
        $('.histoiresor_participation_form .quote').addClass('hide');
        $('.histoiresor_participation_form .explanation').addClass('hide');
        $('.histoiresor_participation_form .all-good').addClass('hide');

        $('.histoiresor_participation_form .working').removeClass('hide');

        $.post('/ho-ajouter-participation', {
            category: category,
            histoireId: histoire_id,
            character: character,
            quote: quote,
            explanation: explanation
        })
        .done(function(data) {
            var data = window.JSON.parse(data);

            var success = data.success;

            if (success) {
                $('.histoiresor_participation_form .working').addClass('hide');
                $('.histoiresor_participation_form .success').removeClass('hide');
            }
        });

        return false;
    });

    $('.parathon-phase1-words .item').click(function(e) {
        $('.parathon-desinscription .custom-button').hide()
        $('.parathon-inscription .custom-button').hide();

        let textInscription = "S'inscrire";

        let parathonInscrit = $('.parathon-inscrit').val();
        if (parathonInscrit === 1) {
            textInscription = "Modifier mon inscription";
        }
        console.log(parathonInscrit);
        console.log(textInscription);

        $('.parathon-inscription .custom-button a').innerHTML = textInscription;

        let value = e.currentTarget.getAttribute('data-value');
        let currentValue = value;

        if ($('.item-' + value).hasClass('active')) {
            $('.item-' + value).removeClass('active');
            currentValue = null;
        }
        else {
            $('.parathon-phase1-words .item').removeClass('active');
            $('.item-' + value).addClass('active');
        }

        if (currentValue === null) {
            if (parathonInscrit === 1) {
                $('.parathon-desinscription .custom-button').hide()
            }
            $('.parathon-inscription .custom-button').hide();
        }
        else {
            if (parathonInscrit === 1) {
                $('.parathon-desinscription .custom-button').show()
            }
            $('.parathon-inscription .custom-button').show();
        }
    })

});
